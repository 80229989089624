import { onValue, ref, set } from "firebase/database";
import { db, initAuth, storage } from "./config";
import {
  getGuestId,
  getIsEmailValid,
  getIsNameValid,
  setDisplay,
  shake,
} from "./common";
import {
  EmailAuthProvider,
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import phUser from "../images/user.svg";

let user;
let auth;
let search;
let step;
let dvLogin,
  dvRegister,
  dvProfile,
  dvWelBack,
  dvAuth,
  dvErrorModal,
  pErrorMsg,
  dvEmailModal,
  dvLoaderModal,
  pLoaderMsg;
let inEmail, inName, inImg, inNewPw, inCurrentPw, inAuthPw;
let ivImg;
let btnLoginCont,
  btnRegCont,
  btnRegChangeEmail,
  btnChangeImg,
  btnCreateProfile,
  btnWelCont,
  btnWelChangeEmail,
  btnWelForgotPw,
  btnAuthConfirm,
  btnAuthForgotPw,
  btnErrorOk,
  btnEmailOk;
let email, pw, name;
let dbLink;
let rememberMe = true;
let chkWelRemember, chkRegRemember;

$(document).ready(function () {
  $(function () {
    getExtras();
    initUi();
    initUser();
  });
});

function getExtras() {
  search = window.location.search;
}
function initUi() {
  dvLogin = document.getElementById("dvLogin");
  dvRegister = document.getElementById("dvRegister");
  dvProfile = document.getElementById("dvProfile");
  dvWelBack = document.getElementById("dvWelBack");
  dvAuth = document.getElementById("dvAuth");
  dvErrorModal = document.getElementById("dv-error-modal");
  dvEmailModal = document.getElementById("dv-email-modal");
  dvLoaderModal = document.getElementById("dv-loader-modal");
  pLoaderMsg = dvLoaderModal.querySelector("p");
  pErrorMsg = document.getElementById("p-error-msg");
  ivImg = dvProfile.querySelector("img");
  inEmail = document.getElementById("inEmail");
  inName = document.getElementById("inName");
  inImg = document.getElementById("inImg");
  inNewPw = dvRegister.querySelector("input");
  inCurrentPw = dvWelBack.querySelector("input");
  inAuthPw = dvAuth.querySelector("input");
  chkRegRemember = document.getElementById("chk-reg-remember");
  chkWelRemember = document.getElementById("chk-wel-remember");
  btnLoginCont = dvLogin.querySelector("button");
  btnRegCont = dvRegister.querySelector("button.btn-green-shw");
  btnRegChangeEmail = dvRegister.querySelector("button.btn-white-gray");
  btnChangeImg = document.getElementById("btnChangeImg");
  btnCreateProfile = document.getElementById("btn-create-profile");
  console.log("====================================");
  console.log("checking: button is: " + btnCreateProfile);
  console.log("====================================");
  btnWelCont = dvWelBack.querySelector("button.btn-green-shw");
  btnWelChangeEmail = dvWelBack.querySelector("button.btn-white-gray");
  btnWelForgotPw = dvWelBack.querySelector("button.btn-pw");
  btnAuthConfirm = dvWelBack.querySelector("button.btn-green-shw");
  btnAuthForgotPw = dvAuth.querySelector("button.btn-pw");
  btnErrorOk = dvErrorModal.querySelector("button");
  btnEmailOk = dvEmailModal.querySelector("button");
  step = dvLogin;
}
function initUser() {
  //if user signed in but no info--> finish setting up your account profile ui
  auth = initAuth;
  let gotUser = false;
  onAuthStateChanged(auth, (data) => {
    user = data;

    if (user) {
      console.log("====================================");
      console.log("checking: user logged");
      console.log("====================================");
      checkUserInfo(user.uid);
    } else {
      console.log("====================================");
      console.log("checking: user not logged");
      console.log("====================================");
      initLs();
      inEmail.focus();
    }
  });
  //user = auth.currentUser;
}

function initLs() {
  $(chkRegRemember).on("click", function () {
    rememberMe = chkRegRemember.checked;
  });
  $(chkWelRemember).on("click", function () {
    rememberMe = chkRegRemember.checked;
  });

  $(btnLoginCont).on("click", function () {
    checkEmail(inEmail.value.trim());
  });

  $(btnRegCont).on("click", function () {
    verifyNewPw();
  });
  $(btnRegChangeEmail).on("click", function () {
    setStep(dvLogin);
    inEmail.focus();
  });
  $(btnChangeImg).on("click", function () {
    inImg.click();
  });
  $(btnCreateProfile).on("click", function () {
    console.log("====================================");
    console.log("checking: button  create profile clicked");
    console.log("====================================");
    validateName();
  });

  inImg.addEventListener("change", function () {
    if (this.files && this.files[0]) {
      ivImg.src = URL.createObjectURL(this.files[0]); // set src to blob url
    } else {
      ivImg.src = phUser;
    }
  });

  $(btnWelCont).on("click", function () {
    verifyPw(inCurrentPw.value.trim());
  });
  $(btnWelChangeEmail).on("click", function () {
    setStep(dvLogin);
    inEmail.focus();
  });
  $(btnWelForgotPw).on("click", function () {
    sendResetPw();
  });

  $(btnAuthConfirm).on("click", function () {
    reAuthUser();
  });
  $(btnAuthForgotPw).on("click", function () {
    sendResetPw();
  });

  $(btnErrorOk).on("click", function () {
    showErrorModal(false, null);
  });
  $(btnEmailOk).on("click", function () {
    showEmailModal(false);
  });
}

function reAuthUser() {
  //used if need to re-verify user's account (user forgot to create profile)
  pw = inAuthPw.value.trim();
  if (pw) {
    showError(dvAuth, false);
    let credential = EmailAuthProvider.credential(email, pw);
    reauthenticateWithCredential(user, credential)
      .then(() => {})
      .catch((_e) => {
        showErrorModal(true, "Sorry, we could not verify your account");
      });
  } else {
    showError(dvAuth, true);
    shake(inAuthPw);
  }
}

function showErrorModal(show, msg) {
  setDisplay(dvErrorModal, show ? "flex" : "none");
  pErrorMsg.textContent = msg
    ? msg
    : "Something went wrong. Please try again later.";
}

function sendResetPw() {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      showEmailModal(true);
    })
    .catch((_e) => {
      showErrorModal(true, "Could not send email to reset your password");
    });
}
function showEmailModal(show) {
  setDisplay(dvEmailModal, show ? "flex" : "none");
}
function validateName() {
  showLoader(true, "Verifying name");
  name = inName.value.trim();
  if (name && getIsNameValid(name)) {
    showError(dvProfile, false);
    checkNameAvailable();
  } else {
    showLoader(false, null);
    showError(dvProfile, true);
    shake(inName);
  }
}
function checkNameAvailable() {
  showLoader(true, "Checking name availability");
  dbLink = ref(db, `uLink/${name}`);
  onValue(
    dbLink,
    (data) => {
      if (data.exists()) {
        showLoader(false, null);
        showErrorModal(
          true,
          `The name, ${name}, is already in use. Please provide a different name`
        );
        inName.select();
      } else {
        if (inImg.files[0]) {
          showLoader(true, "Uploading profile image");
          uploadImage(inImg.files[0]);
          //storageRef.put(inImg.files[0]);
        } else {
          uploadProfile(null);
        }
      }
    },
    { onlyOnce: true }
  );
}

function uploadImage(imgFile) {
  const stImg = ref(storage, `user/${user.uid}/img`);
  const task = uploadBytesResumable(stImg, imgFile);
  task.on(
    "state_changed",
    (data) => {
      const progress = (data.bytesTransferred / data.totalBytes) * 100;
      showLoader(true, `Uploading profile image: ${progress}`);
    },
    (_error) => {
      showLoader(false, null);
      showErrorModal(true, "Could not upload image. Remove image or try again");
    },
    () => {
      //success
      getDownloadURL(task.snapshot.ref).then((url) => {
        uploadProfile(url);
      });
    }
  );
}

function uploadProfile(imgUrl) {
  showLoader(true, "Updating your profile");
  const profile = {
    name: name,
    lcName: name.toLowerCase(),
    img: imgUrl ? "blob" : null,
    imgUrl: imgUrl ? imgUrl : null,
    uid: user.uid,
    guest: false,
    guestId: getGuestId(),
    date: new Date().getTime(),
  };

  let dbUser = ref(db, `user/${user.uid}`);
  set(dbUser, profile)
    .then(() => {
      //TODO: in future, update firebase profile
      showLoader(true, "Creating profile link");
      set(dbLink, user.uid).finally(() => {
        set(ref(db, `uSearch/${user.uid}`), profile.lcName).finally(() => {
          showLoader(false, null);
          showWelcomeDialog();
        });
      });
    })
    .catch((_e) => {
      showErrorModal(
        true,
        "Could not create your profile at this time. Try again later."
      );
    });
}

function showWelcomeDialog() {
  let dvWelcomeMod = document.getElementById("dv-welcome-modal");
  let btnHome = dvWelBack.querySelector("button");

  $(btnHome).on("click", function () {
    initRedirect();
  });
  setDisplay(dvWelcomeMod, "flex");
  let cx = 3;
  const x = setInterval(function () {
    btnHome.innerHTML = `Go home (${cx--})`;

    if (cx < 0) {
      clearInterval(x);
      initRedirect();
    }
  }, 1000);
}
function verifyNewPw() {
  showLoader(true, "Verifying password");
  pw = inNewPw.value.trim();
  if (pw) {
    showError(dvRegister, false);
    registerUser();
  } else {
    showLoader(false, null);
    showError(dvRegister, true);
    shake(inNewPw);
  }
}
function registerUser() {
  showLoader(true, "Creating your account");
  setLoginPersistance();
  createUserWithEmailAndPassword(auth, email, pw)
    .then((userCredential) => {
      showLoader(false, null);
      user = userCredential.user;
      setStep(dvProfile);
      inName.focus();
    })
    .catch((e) => {
      showLoader(false, null);
      showErrorModal(true, "Could not create your account. Try again later.");
    });
}
function verifyPw(p) {
  showLoader(true, "Verifying password");
  pw = p;
  if (pw) {
    showError(dvWelBack, false);
    loginUser();
  } else {
    showLoader(false, null);
    showError(dvWelBack, true);
    shake(inCurrentPw);
  }
}

function loginUser() {
  showLoader(true, "Signing you in");
  setLoginPersistance();
  signInWithEmailAndPassword(auth, email, pw)
    .then((userCredential) => {
      showLoader(false, null);
      user = userCredential.user;
      //checkUserInfo(user.uid);
    })
    .catch((error) => {
      showLoader(false, null);
      showErrorModal(true, "Could not login into your account");
    });
}

function setLoginPersistance() {
  setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  );
}
function checkEmail(nEmail) {
  showLoader(true, "Checking email");
  email = nEmail;
  if (email) {
    if (getIsEmailValid(email)) {
      showError(dvLogin, false);
      checkEmailAccount(email);
    } else {
      showLoader(false, null);
      showError(dvLogin, true);
      shake(inEmail);
    }
  } else {
    showLoader(false, null);
    showError(dvLogin, true);
    shake(inEmail);
  }
}

function checkEmailAccount(email) {
  fetchSignInMethodsForEmail(auth, email)
    .then((signInMethods) => {
      showLoader(false, null);
      if (signInMethods && signInMethods.length > 0) {
        setStep(dvWelBack);
        inCurrentPw.focus();
      } else {
        setStep(dvRegister);
        inNewPw.focus();
      }
    })
    .catch((_error) => {
      showLoader(false, null);
      showErrorModal(true, "Something went wrong. Try again later.");
    });
}
function showError(e, isShow) {
  let dError = e.querySelector(".d-error");
  setDisplay(dError, isShow ? "flex" : "none");
}
function checkUserInfo(uid) {
  let dbUser = ref(db, `uSearch/${uid}`);
  onValue(
    dbUser,
    (data) => {
      if (data.exists()) {
        initRedirect();
      } else {
        setStep(dvProfile);
        inName.focus();
        initLs();
      }
    },
    { onlyOnce: true }
  );
}

function showLoader(show, msg) {
  setDisplay(dvLoaderModal, show ? "flex" : "none");
  pLoaderMsg.textContent = msg ? msg : "uploading data";
}
function setStep(e) {
  setDisplay(step, "none");
  setDisplay(e, "flex");
  step = e;
}
function initRedirect() {
  let loc = `../index.html${search}`;
  let action = search.substring(search.indexOf("?"), search.indexOf("="));
  if (action == "?g") {
    search = search.replace("?g=", "?");
    loc = `../group.html${search}`;
  } else if (search.includes("?action=remove")) {
    loc = `../removeAccount.html?action=success`;
  }

  window.location.replace(loc);
}
